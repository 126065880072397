import { Movement, MovementTypeOptions } from '../types/movement';

export enum MovementTypeColumnMap {
  'I' = 'credit',
  'O' = 'debit'
};

export const splitAmount = (movement: Movement) : Movement & {
  debit?: number,
  credit?: number,
} => {
  const t = movement.movementType as MovementTypeOptions;
  const column = MovementTypeColumnMap[t];

  return {
    ...movement,
    [column]: movement.amount
  };
};