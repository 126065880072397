import { Moment } from 'moment';
import { formatDate, formatMoney, padDecimals } from '../utils/formatter';
import { ITableColumnSchema } from './tableSchema';
import { SummaryItem } from '../components/DataTable/DataTable';
import { MovementKeys } from '../modules/movements/schema';

export const INTERNAL_MOVEMENT = 'internal_movement';

export enum MovementTypeOptions {
  'I' = 'I',
  'O' = 'O',
};

export const MovementType : {
  [x in MovementTypeOptions]: string
} = {
  'I': 'in',
  'O': 'out'
};

export type Movement = {
  id: string;
  description: string;
  createdAt: Moment;
  concept: string;
  treasury: string;
  movementType: MovementTypeOptions;
  movementDate: Moment;
  amount: number;
  voucher: string;
};

export const MovementSchema: Array<ITableColumnSchema> = [
  {
    title: 'modules.movements.schema.description',
    dataIndex: 'description',
    key: 'description',
    translateIf: {
      value: INTERNAL_MOVEMENT,
      translation: 'modules.movements.internalMovement'
    }
  },
  {
    title: 'modules.movements.schema.concept',
    dataIndex: 'concept',
    key: 'concept',
    dataPath: 'concept.description'
  },
  {
    title: 'modules.movements.schema.treasury',
    dataIndex: 'treasury',
    key: 'treasury',
    dataPath: 'treasury.description'
  },
  {
    title: 'modules.movements.schema.movementDate',
    dataIndex: 'movementDate',
    key: 'movementDate',
    formatFn: formatDate
  },
  {
    title: 'modules.movements.schema.movementType',
    dataIndex: 'movementType',
    key: 'movementType',
    mapFromObject: {
      translationNamespace: 'movements',
      objectEnum: MovementType
    }
  },
  {
    title: 'modules.movements.schema.debit',
    dataIndex: 'debit',
    key: 'debit',
    formatFn: formatMoney,
    align: 'right'
  },
  {
    title: 'modules.movements.schema.credit',
    dataIndex: 'credit',
    key: 'credit',
    formatFn: formatMoney,
    align: 'right'
  },
  {
    title: 'modules.movements.schema.voucher',
    dataIndex: 'voucher',
    key: 'voucher',
  },
];

export const TreasuryMovementsColumns = MovementSchema.filter((field) => (
  field.key !== 'treasury'
))

export const getFormattedSchema = (schema: ITableColumnSchema[] = MovementSchema) => (
  schema.map((field) => {
    if (['credit', 'debit'].includes(field.key)) {
      return ({
        ...field,
        formatFn: (value: number) => formatMoney(padDecimals(value)),
      })
    }
    return field;
  })
);

export const getMovementsSummarySchema: (offset?: number) => Array<SummaryItem> = (offset = 0) => [{
  dataKey: 'common.total',
  useAsTranslation: true,
  index: 0,
  colSpan: 5 - offset,
}, {
  dataKey: 'debit',
  useAsTranslation: false,
  index: 1,
  formatAsMoney: true,
  align: 'right'
}, {
  dataKey: 'credit',
  useAsTranslation: false,
  index: 2,
  formatAsMoney: true,
  align: 'right'
}]
