import { splitAmount } from '../../../utils/movement';
import IEndpoint, { HttpMethods } from '../../interfaces/IEndpoint';

const ListMovementsEndpoint: IEndpoint = {
    method: HttpMethods.GET,
    url: 'movements/list',
    formatResponse: ({ list, ...response }: { list: [] }) => {
        const formattedMovementList = list.map((movement) => splitAmount(movement));
        return { ...response, list: formattedMovementList };
    },
    formatError: (errorResponse) => errorResponse.response.data,
};

export default ListMovementsEndpoint;
