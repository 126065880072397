export const padDecimals = (amount: number | string): string => {
  const isString = typeof amount === 'string';
  if (isString) return '';
  return amount.toFixed(2);
};

export const formatMoney = (amount: number | string) => {
  let formattedAmount;
  const isString = typeof amount === 'string';
  if (isString) {
    formattedAmount = parseFloat(amount) < 0 ? amount.replace('-', '-$') : `$${amount}`
  } else {
    formattedAmount = amount < 0 ? `-$${amount * -1}` : `$${amount}`;
  }

  const parts = formattedAmount.split('.');
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const decimalPart = parts[1];

  return amount ? `${integerPart},${decimalPart}` : '-';
}

export const formatDate = (date: Date, locale: string = 'es-AR') => {
  return new Date(date).toLocaleDateString(locale);
}