import React, { useEffect, useState } from 'react';
import { Button, Descriptions, Modal, PageHeader, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import useApi from '../../../hooks/useAPI';
import { Find } from '../../../http/endpoints/treasuries';
import { GetAll as GetAllConcepts } from '../../../http/endpoints/concepts';
import { GetAll as GetAllTreasuries } from '../../../http/endpoints/treasuries';
import { Treasury } from '../../../types/treasury';
import { formatDate, formatMoney, padDecimals } from '../../../utils/formatter';
import TreasuryMovements from '../partials/TreasuryMovements';
import CashDateModal from '../partials/CashDateModal';
import TransferModal from '../partials/TransferModal';
import useStore from '../../../state/store';
import MovementForm, { MovementBody } from '../../movements/views/MovementForm';
import { Create } from '../../../http/endpoints/movements';
import useModal from '../../../hooks/useModal';

const TreasuryDetail = () => {
  const history = useHistory();
  const { Id } = useParams<{Id: string}>();
  const { t } = useTranslation();
  const {
    id: movementModalId,
    visible: movementModalVisible,
    openModal: openMovementModal,
    closeModal: closeMovementModal,
  } = useModal();
  const {
    id: cashDateModalId,
    visible: cashDateModalVisible,
    openModal: openCashDateModal,
    closeModal: closeCashDateModal,
  } = useModal();
  const {
    id: transferModalId,
    visible: transferModalVisible,
    openModal: openTransferModal,
    closeModal: closeTransferModal,
  } = useModal();
  const [treasury, setTreasury] = useState<Treasury|null>(null);
  
  const {
    setConcepts,
    setTreasuries,
    refreshTreasuryDetail,
    setRefreshTreasuryDetail
  } = useStore((state) => state);
  
  const { call: getAllConcepts } = useApi({
    endpoint: GetAllConcepts,
    onSuccess: (data) => { setConcepts(data) },
  });

  const { call: getAllTreasuries } = useApi({
    endpoint: GetAllTreasuries,
    onSuccess: (data) => { setTreasuries(data) },
  });

  useEffect(() => {
    getAllConcepts();
    getAllTreasuries();
  }, []);

  const { call: createMovement } = useApi({
    endpoint: Create,
    onSuccess: () => { setRefreshTreasuryDetail(true) },
  });

  const { call, loading } = useApi({
    endpoint: Find,
    onSuccess: (data) => {
      setTreasury(data);
      setRefreshTreasuryDetail(false);
    },
  });

  const handleCreate = (data: MovementBody) => {
    createMovement({ data });
  };
  
  useEffect(() => {
    if (Id) call({ id: Id });
    return () => { setTreasury(null) };
  }, [Id]);

  useEffect(() => {
    if (Id && refreshTreasuryDetail) call({ id: Id });
    if (!refreshTreasuryDetail) closeMovementModal();
  }, [Id, refreshTreasuryDetail]);

  const refreshDetail = (callback: () => void) => {
    setRefreshTreasuryDetail(true);
    callback();
  };

  if (loading) return <Skeleton active />;

  return treasury && (
    <>
      <Modal
        key={cashDateModalId}
        title={t('modules.treasuries.createCashDate')}
        visible={cashDateModalVisible}
        onCancel={closeCashDateModal}
        footer={null}
      >
        <CashDateModal
          treasuryId={Id}
          onSuccess={() => refreshDetail(closeCashDateModal)}
          onError={(error) => console.log(error)}
          currentBalance={treasury.balance}
        />
      </Modal>
      <Modal
        key={transferModalId}
        title={t('modules.treasuries.transfer')}
        visible={transferModalVisible}
        onCancel={closeTransferModal}
        footer={null}
      >
        <TransferModal
          treasuryId={Id}
          onSuccess={() => refreshDetail(closeTransferModal)}
          onError={(error) => console.log(error)}
          currentBalance={treasury.balance}
        />
      </Modal>
      <Modal
        key={movementModalId}
        title={`${t('common.create')} ${t('modules.movements.single')}`}
        visible={movementModalVisible}
        onCancel={closeMovementModal}
        footer={null}
      >
        <MovementForm 
          onSubmit={handleCreate}
          treasuryId={Id}
        />
      </Modal>
      <PageHeader
        onBack={() => history.goBack()}
        style={{ marginBottom: 16, backgroundColor: 'white' }}
        title={`${t('modules.treasuries.single')}: ${treasury?.description}`}
        extra={[
          <Button
            key="create_movement"
            onClick={openMovementModal}
          >
            {`${t('common.create')} ${t('modules.movements.single')}`}
          </Button>,
          <Button
            key="treasury_transfer"
            onClick={openTransferModal}
          >
            {t('modules.treasuries.transfer')}
          </Button>,
          <Button
            key="create_cash_date"
            onClick={openCashDateModal}
          >
            {t('modules.treasuries.createCashDate')}
          </Button>
        ]}
      >
        <Descriptions size="small" bordered >
          <Descriptions.Item label={t('modules.treasuries.schema.id')} span={3}>
            {treasury.id}
          </Descriptions.Item>
          {
            treasury.cashDate && (
              <Descriptions.Item label={t('modules.treasuries.schema.cashDate')} span={1}>
                {formatDate(treasury.cashDate)}
              </Descriptions.Item>
            )
          }
          <Descriptions.Item label={t('modules.treasuries.schema.initialBalance')} span={2}>
            {formatMoney(padDecimals(treasury.initialBalance))}
          </Descriptions.Item>
          <Descriptions.Item label={t('modules.treasuries.schema.balance')}>
            {formatMoney(padDecimals(treasury?.balance))}
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>
      { !!Id && <TreasuryMovements treasuryId={Id} /> }
    </>
  );
};

export default TreasuryDetail;